<template>
  <!-- Modal. Contiene el card del listado de nóminas. -->
  <b-modal
    id="nominasModal"
    title="Nóminas"
    v-model="show"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    dialog-class="x-modal-lg"
  >
    <template #modal-title>
      <!-- Tags -->
      <div class="my-2">
        <b-badge variant="success" v-if="mes">Mes: {{ getMonthById(mes).value }}</b-badge>
      </div>
    </template>

    <!-- Body -->
    <template>
      <div class="card card-header-actions">
        <div class="card-header">
          Conceptos del mes de {{ getMonthById(mes).value }}
          <!-- Nuevo registro. Abre sidebar. -->
          <b-button v-b-toggle.nominasFormSidebar size="sm" variant="success">Crear</b-button>
        </div>
        <div class="card-body p-1"><nominas-list :mes="mes"></nominas-list></div>
        <div class="card-footer">
          <!-- Descarga de formatos por quincena -->
          <div class="d-flex justify-content-left align-items-left" style="font-size: 1rem">
            <check-authorization :requiresAuthorizations="['exportar nomina']">
              <b-button class="mr-3 mb-2 mb-sm-0"
                        size="sm"
                        variant="primary"
                        @click="downloadConceptos(mes,1)">Conceptos 1a. Qna.</b-button>
            </check-authorization>
            <check-authorization :requiresAuthorizations="['exportar nomina']">
            <b-button class="mr-3 mb-2 mb-sm-0"
                      size="sm"
                      variant="secondary"
                      @click="downloadConceptos(mes,2)">Conceptos 2a. Qna.</b-button>
            </check-authorization>
          </div>
        </div>
      </div>

      <nominas-sidebar :mes="mes"></nominas-sidebar>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import NominasList from '@/components/Nominas/NominasList'
import NominasSidebar from '@/components/Nominas/NominasSidebar'

export default {
  name: 'NominasModal',

  components: {
    NominasList,
    NominasSidebar
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    mes: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      show: this.value,

      showImportForm: false
    }
  },

  computed: mapGetters('UIModule', ['getMonthById']),

  watch: {
    value (newValue) {
      this.show = newValue
    },

    show (newValue) {
      this.$emit('input', newValue)
    }
  },

  methods: {
    onShowImportForm (value) {
      this.showImportForm = value
    },

    async downloadConceptos (mes, qna) {
      const payload = {
        mes: mes,
        qna: qna
      }
      const getval = await this.$store.dispatch('momentosContablesModule/exportNominaByMes', payload)

      await this.download(getval, `nomina.${mes}.${qna}.xlsx`)

      this.$alert(
        { message: `Solicitud de descarga de conceptos de nómina en mes ${mes}, quincena ${qna}.` },
        'Nóminas.',
        'notificationNominasAlertPlace',
        { status: 'info', closeOnTimeout: true }
      )
    },

    download (data, name) {
      const blob = new Blob([data], { type: 'blob' })
      const e = document.createEvent('MouseEvents')
      const a = document.createElement('a')

      //
      // Nombre del archivo
      a.download = name

      a.href = window.URL.createObjectURL(blob)
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
      a.dispatchEvent(e)
    }
  }
}
</script>
